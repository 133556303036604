import { createStore } from 'vuex'
import axios from "axios";

export default createStore({
  state: {
    token: null,
    authentication: false,
  },
  getters: {
    token: (state) => {
      return state.token
    },
    authentication: (state) => {
      return state.authentication
    }
  },
  actions: {
    async login({commit}, {login, password}) {
      await axios.post(process.env.VUE_APP_URL_API + `/login`, {login, password})
          .then(response => {
            if (response.status === 200) {
              commit('setToken', response.data.access_token)
              commit('setAuthentication', true)
            }
            return response.data;
          })
          .catch(error => {
            console.log(error.response)
          });
    }
  }   ,
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setAuthentication(state, authentication) {
      state.authentication = authentication;
    },
  },
  modules: {
  }
})
