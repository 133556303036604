<template>
  <div>
    <table class="container">
      <thead>
      <tr>
        <th><h1>Tartib</h1></th>
        <th><h1>Miqdor</h1></th>
        <th><h1>Narx</h1></th>
        <th><h1>Havola</h1></th>
        <th><h1>Nusxa</h1></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="data in datas" :key="data.id">
        <td>{{ data.sort }}</td>
        <td>{{ data.megabyte }}</td>
        <td>{{ data.price }}</td>
        <td class="hover_mouse"><a :href="'tel:' + data.code"></a>
          <div><img src="@/assets/phone.svg" alt="">
            <p style="margin: 0; padding: 0;">&#160;&#160;Faollashtirish</p></div>
        </td>
        <td class="hover_mouse" @click="copy(data.code)"> Nusxa olish</td>
      </tr>

      </tbody>
    </table>
    <input type="text" v-model="code" id="code" style="position: absolute; top: -1000px;">
  </div>

</template>

<script>
import axios from "axios"
import {mapGetters} from "vuex";

export default {
  name: 'Home',
  data() {
    return {
      datas: [],
      type: 'hidden',
      code: 'null'
    }
  },
  methods: {
    async load() {
      let result = await axios.get(process.env.VUE_APP_URL_API + "/row");
      let datas = Object.values(result.data);
      this.datas = datas.sort(function (a, b) {
        if (a.sort < b.sort) return -1;
        if (a.sort === b.sort) return 0;
        if (a.sort > b.sort) return 1;
      });
    },
    copy(code) {
      this.code = code;
      this.type = 'text';
      setTimeout(function (){
        let copyElement = document.getElementById('code');
        copyElement.focus();
        copyElement.select();
        copyElement.setSelectionRange(0, 99999)
        try {
          var successful = document.execCommand('copy');
          var msg = successful ? 'successful' : 'unsuccessful';
          alert('Nusxa olindi!');
        } catch (err) {
          alert('Nusxa olishda muammo yuz berdi!');
        }
        this.type = 'hidden';
        window.getSelection().removeAllRanges()
      }, 200)

    },
  },
  async mounted() {
    this.load()
  }

}
</script>
<style src="@/assets/css/table.css"></style>
